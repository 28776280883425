import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { loginParams, loginUser, registerUser } from '../api/taskApi';
import { useDispatch, useSelector } from 'react-redux';
import { finishLoading, setError, startLoading } from '../state/loadingSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { setKeys } from '../state/keysSlice';
import {
  encryptText,
  generatePasswordKeys,
  generateSeed,
} from '../utils/encryption';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

import PropTypes from 'prop-types'; // Import PropTypes

export default function AuthPage({ isSignUp }) {
  //TODO: Daca e deja logat atunci redirect pe /.

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const { loading, error } = useSelector((state) => state.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(startLoading(isSignUp ? 'Registering...' : 'Logging in...'));

    // Encrypt passwords and get keys

    if (isSignUp) {
      if (password.length < 8) {
        dispatch(
          setError({
            message: 'Password must be at least 8 characters long',
          })
        );
        return;
      }

      if (password !== passwordRepeat) {
        dispatch(
          setError({
            message: 'Passwords do not match',
          })
        );
        return;
      }
    }
    try {
      let response;
      let rootKeys;
      let encryptedItemsKey;
      let encryptedItemsKeys = {};
      let encryptedItemsKeyId;
      if (isSignUp) {
        rootKeys = await generatePasswordKeys(email, password);
        //Generate itemsKey
        const itemsKey = await generateSeed();
        encryptedItemsKey = encryptText(itemsKey, rootKeys.masterKey);

        response = await registerUser({
          email,
          password: rootKeys.serverPassword,
          pw_nonce: rootKeys.pw_nonce,
          encryptedItemsKey,
          remember_me: rememberMe,
        });

        encryptedItemsKeys[response.encrypted_items_key_id] = encryptedItemsKey;
        encryptedItemsKeyId = response.encrypted_items_key_id;
      } else {
        const pw_nonce_response = await loginParams({ email });
        const pw_nonce_from_api = pw_nonce_response.pw_nonce;
        rootKeys = await generatePasswordKeys(
          email,
          password,
          pw_nonce_from_api
        );

        response = await loginUser({
          email,
          password: rootKeys.serverPassword,
          remember_me: rememberMe,
        });
        encryptedItemsKeys = response.encrypted_items_keys;
        encryptedItemsKeyId = response.encrypted_items_key_id;
      }
      console.log({ response });
      if (response.success) {
        // Generate keys from pass if success
        dispatch(
          setKeys({
            identifier: email,
            pw_nonce: rootKeys.pw_nonce,
            masterKey: rootKeys.masterKey,
            encryptedItemsKeys,
            encryptedItemsKeyId,
          })
        );
        return navigate('/');
      }
    } catch (error) {
      console.log({ error });
      dispatch(
        setError({
          message: isSignUp ? 'Register failed' : 'Login failed',
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  };

  // const date_created_timezone = new Date('2024-10-02T06:26:05.362Z');
  // alert(date_created_timezone);
  // alert(new Date());

  // const created_time_ago = formatDistance(date_created_timezone, new Date(), {
  //   addSuffix: true,
  // });
  // alert(created_time_ago);

  return (
    <div className='h-full overflow-auto bg-stone-100'>
      <div className='flex flex-col md:flex-row p-5 space-y-3 md:space-y-0 md:space-x-6 items-center justify-center min-h-screen '>
        <div className=' w-fill  items-center flex flex-col max-w-96 '>
          <span className='text-xl font-bold'>PerspecTask</span>
          <div className='hidden md:block mt-7'>
            <div className='flex flex-row items-baseline gap-3'>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className='text-emerald-500'
              />
              <div>
                <div className='font-semibold'>End-to-End Encryption</div>
                <div className='text-sm'>
                  Privacy is the default for all plans
                </div>
              </div>
            </div>

            <div className='flex flex-row items-baseline gap-3 mt-5'>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className='text-emerald-500'
              />
              <div>
                <div className='font-semibold'>
                  Multiple parents on same Task
                </div>
                <div className='text-sm'>
                  Truly understand the Perspective of your Tasks and which is
                  are the most impactful.
                </div>
              </div>
            </div>

            <div className='flex flex-row items-baseline gap-3 mt-5'>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className='text-emerald-500'
              />
              <div>
                <div className='font-semibold'>Search Tasks</div>
                <div className='text-sm'>Instantly find any desired Task</div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-fill max-w-sm p-8 bg-white rounded-lg shadow-md '>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                disabled={loading}
                type='email'
                name='email'
                id='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email address'
                className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                required
              />
            </div>
            <div className='mt-4'>
              <input
                disabled={loading}
                type='password'
                name='password'
                id='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                required
              />
            </div>
            <div
              className={`transition-all duration-500 overflow-hidden ${
                isSignUp ? 'mt-4 max-h-40 ' : 'max-h-0 mt-0'
              }`}
            >
              <span className='text-xs text-stone-500 leading-none text-justify'>
                Your tasks are encrypted with your password, so{' '}
                <span className='font-semibold leading-none'>
                  {"there's no reset option."}
                </span>{' '}
                If you forget your password, you’ll permanently lose access to
                your data.
              </span>
              <input
                disabled={loading}
                type='password'
                name='repeat_password'
                id='repeat_password'
                placeholder='Repeat Password'
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                className='w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                required={isSignUp}
              />
            </div>
            <div className='flex items-center justify-between mt-4'>
              <div
                className='flex items-center'
                data-tooltip-content='If you are on a trusted device, you can check this option to permanently remain logged in until you manually log out, otherwise you will automatically be logged out after 1 hour.'
                data-tooltip-class-name='max-w-sm'
                data-tooltip-id='auth-tooltip'
              >
                <input
                  disabled={loading}
                  id='remember_me'
                  name='remember_me'
                  checked={rememberMe}
                  onChange={handleCheckboxChange}
                  type='checkbox'
                  className='w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
                />
                <label
                  htmlFor='remember_me'
                  className='ml-2 text-sm text-gray-900'
                >
                  Remember me
                </label>
              </div>
            </div>

            <div className='mt-4'>
              <button
                disabled={loading}
                type='submit'
                className='w-full px-4 py-2 text-sm font-medium text-white  rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-1 enabled:bg-indigo-600 disabled:bg-stone-400'
              >
                {loading ? (
                  <span>
                    <FontAwesomeIcon icon={faSpinner} spin className='mr-1' />
                    Loading...
                  </span>
                ) : isSignUp ? (
                  'Sign Up'
                ) : (
                  'Sign In'
                )}
              </button>
            </div>

            <div
              className={`text-red-500 text-center font-semibold  border-red-500 bg-red-300 rounded-md  text-sm
            transition-all duration-500 overflow-hidden ${
              error ? ' max-h-40 py-2 mt-2 border' : 'max-h-0 mt-0'
            }`}
            >
              {error}
            </div>
          </form>
          <div className='mt-4 text-sm text-center'>
            {isSignUp ? (
              <span>
                Already have an account?{' '}
                <Link
                  to='/login'
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                  Sign in
                </Link>
              </span>
            ) : (
              <span>
                {"Don't have an account? "}
                <Link
                  to='/register'
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                  Sign up
                </Link>
              </span>
            )}
          </div>
          <Tooltip id='auth-tooltip' />
        </div>
      </div>
    </div>
  );
}

// Add prop types validation
AuthPage.propTypes = {
  isSignUp: PropTypes.bool.isRequired, // Validate isSignUp as a required boolean
};
