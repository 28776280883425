import { format, startOfWeek, endOfWeek, isSameDay } from 'date-fns';
import { PAGES } from './constants';

function getFormattedDay(date: Date) {
  return {
    id: format(date, "'d'-d-M-yyyy"), //'d-24-7-2024'
    title: format(date, 'EEEE'),
    subtitle: format(date, 'MMMM dd'),
    type: 'Today',
    default: format(date, 'EEE, dd MMM'),
    isSameDate: isSameDay(date, new Date()),
    space: PAGES.DAYS,
    pretty: format(date, 'EEEE, dd MMM, yyyy'),
    rawDate: date,
  };
}

function getFormattedWeek(date: Date) {
  // Calculate the start and end of the week
  const start = startOfWeek(date, { weekStartsOn: 1 }); // Start on Monday
  const end = endOfWeek(date, { weekStartsOn: 1 }); // End on Sunday

  const startDay = format(start, 'd');
  const endDay = format(end, 'd');
  const startMonth = format(start, 'MMM');
  const endMonth = format(end, 'MMM');

  // Formatting the week
  const weekString =
    startMonth === endMonth
      ? `${startDay}-${endDay} ${startMonth}`
      : `${startDay} ${startMonth}-${endDay} ${endMonth}`;

  return {
    id: format(date, "'w'-w-yyyy", { weekStartsOn: 1 }), //'w-23-2024',
    title: format(date, "'W'ww, yyyy", { weekStartsOn: 1 }),
    subtitle: weekString,
    type: 'Week',
    default: weekString,
    isSameDate: isSameDay(date, new Date()),
    space: PAGES.WEEKS,
    pretty:
      format(date, "'Week 'ww, yyyy", { weekStartsOn: 1 }) + ': ' + weekString,
    prettyShort: format(date, "'W'ww", { weekStartsOn: 1 }),
    rawDate: date,
  };
}

function getFormattedMonth(date: Date) {
  return {
    id: format(date, "'m'-M-yyyy"), //'m-7-2024',
    type: 'Month',
    default: format(date, 'MMMM'),
    title: format(date, 'MMMM'),
    subtitle: format(date, 'yyyy'),
    isSameDate: isSameDay(date, new Date()),
    space: PAGES.MONTHS,
    pretty: format(date, 'MMMM yyyy'),
    prettyShort: format(date, 'MMMM'),
    rawDate: date,
  };
}

function getFormattedYear(date: Date) {
  return {
    id: format(date, "'y'-yyyy"), //'y-2024',
    type: 'Year',
    default: format(date, 'yyyy'),
    title: format(date, 'yyyy'),
    subtitle: format(date, 'yyyy'),
    isSameDate: isSameDay(date, new Date()),
    space: PAGES.YEARS,
    pretty: format(date, 'yyyy'),
    rawDate: date,
  };
}

function getFormattedDate(date: Date) {
  return {
    D: getFormattedDay(date),
    W: getFormattedWeek(date),
    M: getFormattedMonth(date),
    Y: getFormattedYear(date),
    L: { pretty: 'Life' },
  };
}

function getPrettyDate(
  date: Date | null,
  dateType: 'D' | 'W' | 'M' | 'Y' | 'L' | null
) {
  if (!dateType) {
    return null;
  }
  if (!date) {
    return null;
  }

  return getFormattedDate(date)[dateType]['pretty'];
}

export {
  getFormattedDate,
  getFormattedDay,
  getFormattedWeek,
  getFormattedMonth,
  getFormattedYear,
  getPrettyDate,
};
