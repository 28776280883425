import { createSlice } from '@reduxjs/toolkit';
import { decryptText } from '../utils/encryption';

interface Task {
  id: string;
  title: string;
  title_encrypted: string;
  title_item_key_encrypted: string;
  title_items_key_id: string | null;
  description: string | null;
  description_encrypted: string;
  description_item_key_encrypted: string;
  description_items_key_id: string | null;
  scheduled_date: Date | null;
  scheduled_type: 'D' | 'W' | 'M' | 'Y' | 'L' | null;
  columnId: string | null;
  status: number;
  status_date: string | null;
  children: string[];
  parents: string[];
  date_created: string;
}

interface Tasks {
  [key: string]: Task;
}

// Define initial state
const initialState: Tasks = {};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialState,
  reducers: {
    fillTasks: (state, action) => {
      Object.assign(state, action.payload.tasks);
    },
    addTaskToTasks: (state, action) => {
      const task = action.payload;
      state[task.id] = task;
    },
    decryptTasks: (state, action) => {
      const { masterKey, encryptedItemsKeys, tasks = {} } = action.payload;
      const tasksToDecrypt =
        Object.keys(tasks).length === 0 || tasks === null ? state : tasks;

      const itemsKeys = {};
      Object.keys(encryptedItemsKeys).forEach((encryptedItemsKeyId) => {
        const itemsKeyEncrypted = encryptedItemsKeys[encryptedItemsKeyId];
        const itemsKey = decryptText(itemsKeyEncrypted, masterKey);
        itemsKeys[encryptedItemsKeyId] = itemsKey;
      });
      // Iterate over the tasks object
      Object.keys(tasksToDecrypt).forEach((taskId) => {
        const task = tasksToDecrypt[taskId];

        // Check if task has 'title_encrypted' and decrypt it
        if (
          task.title_encrypted &&
          task.title_item_key_encrypted &&
          task.title_items_key_id
        ) {
          try {
            const itemKey = decryptText(
              task.title_item_key_encrypted,
              itemsKeys[task.title_items_key_id]
            );
            const decryptedTitle = decryptText(task.title_encrypted, itemKey);
            // console.log({ itemKey, decryptedTitle });
            state[taskId].title = decryptedTitle; // Add the decrypted title to the task
          } catch (err) {
            console.error(
              'Corrupted or wrong password for title of: ' + taskId
            );
          }
        }

        if (
          task.description_encrypted &&
          task.description_item_key_encrypted &&
          task.description_items_key_id
        ) {
          try {
            const itemKey = decryptText(
              task.description_item_key_encrypted,
              itemsKeys[task.description_items_key_id]
            );
            const decryptedDescription = decryptText(
              task.description_encrypted,
              itemKey
            );
            // console.log({ itemKey, decryptedDescription });
            state[taskId].description = decryptedDescription; // Add the decrypted title to the task
          } catch (err) {
            console.error(
              'Corrupted or wrong password for description of: ' + taskId
            );
          }
        } else {
          state[taskId].description = '';
        }
      });
    },
    removeTaskFromTasks: (state, action) => {
      delete state[action.payload];
    },
    updateTaskInTasks: (state, action) => {
      const { id, changes } = action.payload;
      if (state[id]) {
        state[id] = { ...state[id], ...changes };
      }
      console.log(JSON.stringify(state[id]));
      console.log(JSON.stringify(action.payload));
    },
    combineTasksInTasks: (state, action) => {
      const { parentId, childId } = action.payload;

      if (!state[parentId].children) {
        state[parentId].children = [];
      }
      if (state[parentId].children.indexOf(childId) === -1) {
        state[parentId].children.push(childId);
      } else {
        console.log('Subtask already in the task');
        return;
      }
      if (!state[childId].parents) {
        state[childId].parents = [];
      }
      if (state[childId].parents.indexOf(parentId) === -1) {
        state[childId].parents.push(parentId);
      } else {
        console.log('Parent already in the subtask');
      }
    },
    uncombineTasksInTasks: (state, action) => {
      const { parentId, childId } = action.payload;

      if (!state[parentId].children) {
        state[parentId].children = [];
      }
      const index = state[parentId].children.indexOf(childId);
      if (index !== -1) {
        state[parentId].children.splice(index, 1);
      }
      if (!state[childId].parents) {
        state[childId].parents = [];
      }
      const index2 = state[childId].parents.indexOf(parentId);
      if (index2 !== -1) {
        state[childId].parents.splice(index2, 1);
      }
    },
    uncombineTasksByDelete: (state, action) => {
      const { taskId } = action.payload;

      if (state[taskId].parents) {
        state[taskId].parents.forEach((parentId) => {
          if (state[parentId].children) {
            const index = state[parentId].children.indexOf(taskId);
            if (index !== -1) {
              state[parentId].children.splice(index, 1);
            }
          }
        });
      }

      if (state[taskId].children) {
        state[taskId].children.forEach((childId) => {
          if (state[childId].parents) {
            const index = state[childId].parents.indexOf(taskId);
            if (index !== -1) {
              state[childId].parents.splice(index, 1);
            }
          }
        });
      }
    },
  },
});

export const {
  fillTasks,
  addTaskToTasks,
  decryptTasks,
  removeTaskFromTasks,
  updateTaskInTasks,
  combineTasksInTasks,
  uncombineTasksInTasks,
  uncombineTasksByDelete,
} = tasksSlice.actions;

export default tasksSlice.reducer;
