import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import './Modal.css'; // Import the CSS for transitions

import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { closeModal } from '../../state/modalSlice';
import ColumnDroppable from '../Column/ColumnDroppable';
import { PAGES } from '../../utils/constants';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import TaskEdit from './TaskEdit';
import TaskPath from './TaskPath';
import TaskDate from './TaskDate';
import TaskMore from './TaskMore';
import { formatDistance } from 'date-fns';
import { RootState } from '../../state/store'; // Adjust the import based on your project structure
import { setIsMobile } from '../../state/mobileSlice';

ReactModal.setAppElement('#root');

const Modal = () => {
  const modalData = useSelector((state: RootState) => state.modal); // Specify the type of state
  const tasksData = useSelector((state: RootState) => state.tasks);
  const { isMobile } = useSelector((state: RootState) => state.mobile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!modalData.isOpen) {
    return;
  }

  const { taskId } = modalData.content as { taskId: string }; // Type assertion

  const { id = '0', children = [], parents, date_created } = tasksData[taskId];
  const date_created_timezone = new Date(date_created);

  const created_time_ago = formatDistance(date_created_timezone, new Date(), {
    addSuffix: true,
  });

  const childrenCompletedCount =
    children.length > 0
      ? children.reduce((count, child) => {
          return tasksData[child].status ? count + 1 : count;
        }, 0)
      : 0;

  const onClose = () => {
    dispatch(closeModal());
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, combine } = result;
    if (!destination) {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
  };

  const handleOpenTaskPerspectiveClick = () => {
    dispatch(closeModal());
    navigate('/task-perspective/' + id);
  };

  return (
    <ReactModal
      isOpen={modalData.isOpen}
      onRequestClose={onClose}
      className='Modal bottom-0 sm:top-3 sm:bottom-auto rounded-t-md sm:rounded-md w-full sm:w-4/5 lg:w-2/3 2xl:w-1/2'
      overlayClassName='Overlay'
      closeTimeoutMS={500} // Match the CSS transition duration
    >
      <div className='flex flex-col h-full'>
        <div className='flex flex-row  bg-stone-100 items-start p-5 pb-2 pt-2 rounded-t-lg'>
          <div className='grow italic text-stone-400 text-sm'>
            <TaskDate taskId={taskId} />
            {!isMobile && (
              <div className='hidden sm:block'>
                <TaskPath taskId={taskId} />
              </div>
            )}
          </div>
          <div className='flex flex-row'>
            <button
              data-tooltip-id='modal-tooltip'
              data-tooltip-content="Open Task's perspective"
              onClick={handleOpenTaskPerspectiveClick}
              className=' h-7 w-7 flex items-center justify-center rounded-full 
              transition duration-300 hover:bg-stone-400'
            >
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </button>

            <TaskMore taskId={taskId} />
            <button
              onClick={onClose}
              className='text-xl transition duration-300 hover:bg-stone-400 w-7 h-7 flex items-center justify-center rounded-full'
            >
              &#10005;
            </button>
          </div>
        </div>
        <div className='bg-stone-100 border-b border-stone-200'>
          <TaskEdit taskId={taskId} />
        </div>
        <div className='flex flex-col grow p-5 pt-1 rounded-b-lg'>
          <h2 className='mt-2 font-bold'>
            Sub-tasks (
            {childrenCompletedCount ? childrenCompletedCount + '/' : ''}
            {children.length})
          </h2>
          <div className='ml-2 mr-2 -mb-14 grow'>
            <DragDropContext onDragEnd={onDragEnd}>
              <ColumnDroppable
                columnId={id}
                tasksIds={children}
                space={PAGES.MODAL}
              />
            </DragDropContext>
          </div>
        </div>
        {isMobile && parents.length > 0 && (
          <div className='block sm:hidden italic text-stone-400 text-sm bg-stone-100 items-start p-5 pb-2  pt-2 border-t border-stone-200'>
            <TaskPath taskId={taskId} />
          </div>
        )}
        <div className='bg-stone-100 p-2 pt-1 px-5 text-right border-t border-stone-200'>
          <span className='italic text-xs text-stone-600'>
            <FontAwesomeIcon icon={faClock} className='mr-1' />
            Created{' '}
            <span
              data-tooltip-id='task-tooltip'
              data-tooltip-content={date_created_timezone.toString()}
              data-tooltip-place='top'
              className='cursor-help'
            >
              {created_time_ago}
            </span>
            .
          </span>
        </div>
        <Tooltip id='modal-tooltip' place='bottom' />
      </div>
    </ReactModal>
  );
};

export default Modal;
