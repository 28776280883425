import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import Column from '../components/Column/Column';
import { useSelector, useDispatch } from 'react-redux';
import { moveTask } from '../state/columnsSlice';
import {
  combineTasksInTasks,
  uncombineTasksInTasks,
  updateTaskInTasks,
} from '../state/tasksSlice';
import {
  getFormattedDate,
  getFormattedDay,
  getFormattedMonth,
  getFormattedWeek,
  getFormattedYear,
} from '../utils/dateFormatter';
import { PAGES } from '../utils/constants';
import { add } from 'date-fns';
import TimelineNavigator from '../components/TimelineNavigator/TimelineNavigator';
import { finishLoading, setError, startLoading } from '../state/loadingSlice';
import { updateTask } from '../api/taskApi';
import { idToScheduled } from '../utils/idToScheduled';
import { combineTasks } from '../api/taskApi';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../state/store';

// Define the interface for Perspective
interface Perspective {
  id: string;
  title: string;
  subtitle: string;
  space: string;
  rawDate: Date;
}

export default function DaysPage({ spaceType }: { spaceType: string }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMobile } = useSelector((state: RootState) => state.mobile);
  useEffect(() => {
    if (spaceType === PAGES.DEFAULT && isMobile) {
      navigate('/days');
    }
  }, [isMobile, navigate]);

  const todayDate = useSelector(
    (state: RootState) => state.displayDate.displayDate
  );

  // Ref to store the computed perspectives
  const computedPerspectives = useRef<{
    [key: string]: Perspective[]; // Ensure this is explicitly an array of Perspective
  }>({});

  const perspectives = useMemo((): Perspective[] => {
    const key = `${spaceType}_${todayDate}`;
    if (computedPerspectives.current[key]) {
      return computedPerspectives.current[key]; // This should now be recognized as Perspective[]
    }
    // console.log('Recalculeaza MEMO');

    //Generate default columns
    // const today = new Date();
    const today = new Date(todayDate); // Ensure today is a Date object
    const todayPerspectives = getFormattedDate(today);
    const newPerspectives: {
      [key: string]: Perspective[];
    } = {}; // Define the type explicitly
    newPerspectives.DEFAULT = [
      {
        id: todayPerspectives.D.id, //'d-24-7-2024',
        title: todayPerspectives.D.type,
        subtitle: todayPerspectives.D.default,
        space: todayPerspectives.D.space,
        rawDate: todayPerspectives.D.rawDate,
      },
      {
        id: todayPerspectives.W.id,
        title: todayPerspectives.W.type,
        subtitle: todayPerspectives.W.default,
        space: todayPerspectives.W.space,
        rawDate: todayPerspectives.W.rawDate,
      },
      {
        id: todayPerspectives.M.id,
        title: todayPerspectives.M.type,
        subtitle: todayPerspectives.M.default,
        space: todayPerspectives.M.space,
        rawDate: todayPerspectives.M.rawDate,
      },
      {
        id: todayPerspectives.Y.id,
        title: todayPerspectives.Y.type,
        subtitle: todayPerspectives.Y.default,
        space: todayPerspectives.Y.space,
        rawDate: todayPerspectives.Y.rawDate,
      },
      {
        id: 'l',
        title: 'Life',
        subtitle: '',
        space: PAGES.LIFE,
        rawDate: new Date(),
      },
    ];

    // TODO: Genereaza array-ul de zile de la ziua de start (-1, si o poate incrementa din buton), si cate zile (asta o fixez eu din constante)
    newPerspectives[PAGES.DAYS] = [-1, 0, 1, 2, 3].map((timeTravel) => {
      return getFormattedDay(add(today, { days: timeTravel }));
    });
    newPerspectives[PAGES.DAYS].push(getFormattedWeek(today));

    newPerspectives[PAGES.WEEKS] = [-1, 0, 1, 2, 3].map((timeTravel) => {
      return getFormattedWeek(add(today, { weeks: timeTravel }));
    });
    newPerspectives[PAGES.WEEKS].push(getFormattedMonth(today));

    newPerspectives[PAGES.MONTHS] = [-1, 0, 1, 2, 3].map((timeTravel) => {
      return getFormattedMonth(add(today, { months: timeTravel }));
    });
    newPerspectives[PAGES.MONTHS].push(getFormattedYear(today));

    newPerspectives[PAGES.YEARS] = [-1, 0, 1, 2, 3].map((timeTravel) => {
      return getFormattedYear(add(today, { years: timeTravel }));
    });

    computedPerspectives.current[key] = newPerspectives[spaceType];

    return newPerspectives[spaceType];
  }, [todayDate, spaceType]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    // Specify the type for result
    const { source, destination, combine } = result;

    // combining item
    if (combine) {
      // console.log('Combined item ID:', combine.draggableId);
      // console.log('Dragging item ID:');
      const childId = result.draggableId;
      const parentId = combine.draggableId;
      dispatch(
        combineTasksInTasks({
          parentId: combine.draggableId,
          childId: result.draggableId,
        })
      );

      //Optimistic update in DB!
      dispatch(startLoading('Updating tasks...'));
      try {
        await combineTasks({ id: childId, parent_id: parentId });
      } catch (error: unknown) {
        if (
          error?.response?.data?.message !== 'Not allowed' &&
          error.code !== 'ERR_NETWORK'
        ) {
          console.log('Rollback combine viz!');
          dispatch(
            uncombineTasksInTasks({
              parentId: combine.draggableId,
              childId: result.draggableId,
            })
          );
        }
        dispatch(
          setError({
            message: "Can't update task, please try again.",
            error: error,
          })
        );
      } finally {
        dispatch(finishLoading());
      }
      return;
    }

    if (!destination) {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    dispatch(moveTask({ source, destination }));

    //Optimistic update in DB!
    dispatch(startLoading('Updating task...'));
    const taskId = result.draggableId;
    const updateData = idToScheduled(destination.droppableId);
    // console.log('MOVE: ' + JSON.stringify(result.destination.droppableId));
    // console.log('UPDATE DATA: ' + JSON.stringify(updateData));

    try {
      await updateTask({ id: taskId, data: updateData });
      dispatch(
        updateTaskInTasks({
          id: taskId,
          changes: { ...updateData, columnId: destination.droppableId },
        })
      );
    } catch (error) {
      // If DB didn't successfully move the task, revert the change in Redux.
      dispatch(moveTask({ source: destination, destination: source }));
      dispatch(
        setError({
          message: "Can't update task, please try again.",
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  }, []);

  return (
    <>
      <div className='space flex flex-rows flex-1 h-full content-stretch relative'>
        <DragDropContext onDragEnd={onDragEnd}>
          {perspectives.map((perspective, index) => {
            return (
              <Column
                key={perspective.id}
                perspective={perspective}
                spaceType={spaceType}
                columnIndex={index}
              />
            );
          })}
        </DragDropContext>
        {spaceType == PAGES.DEFAULT ? (
          ''
        ) : (
          <TimelineNavigator perspectiveType={spaceType} />
        )}
      </div>
    </>
  );
}
