import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  getDate,
  getDaysInMonth,
  getDaysInYear,
  isAfter,
  isBefore,
  lastDayOfMonth,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { PAGES } from './constants';

interface PeriodObject {
  percentage: number;
  elapsed: number | null;
  total: number;
  words: string;
}

function getPeriodPercentage(
  spaceDate: Date,
  type: string
): PeriodObject | null {
  const now = new Date(); //'2024-10-31T06:41:51.704Z'
  const res: PeriodObject = {
    percentage: 0, // Initialize with default values
    elapsed: null,
    total: 0,
    words: '',
  };

  if (type === PAGES.DAYS) {
    res.total = 24;
    if (isBefore(spaceDate, startOfDay(now))) {
      res.percentage = 100;
      res.elapsed = 24;
      res.words = `${res.elapsed}/${res.total}h`;
    } else if (isAfter(spaceDate, endOfDay(now))) {
      res.percentage = 0;
      res.elapsed = 0;
      res.words = `${res.elapsed}/${res.total}h`;
    } else {
      // only 2 digits after the decimal
      res.percentage =
        parseFloat(
          (
            (differenceInMinutes(startOfDay(spaceDate), now) / 1440) *
            100
          ).toFixed(2)
        ) * -1;
      res.words = `${differenceInHours(
        now,
        startOfDay(spaceDate)
      )}h ${differenceInMinutes(now, startOfHour(now))}m/${res.total}h`;
    }
    return res;
  }

  if (type === PAGES.WEEKS) {
    res.total = 7;
    if (isBefore(spaceDate, startOfWeek(now, { weekStartsOn: 1 }))) {
      res.percentage = 100;
      res.elapsed = 7;
      res.words = `${res.elapsed}/${res.total}d`;
    } else if (isAfter(spaceDate, endOfWeek(now, { weekStartsOn: 1 }))) {
      res.percentage = 0;
      res.elapsed = 0;
      res.words = `${res.elapsed}/${res.total}d`;
    } else {
      // only 2 digits after the decimal
      res.percentage =
        parseFloat(
          (
            (differenceInMinutes(
              startOfWeek(spaceDate, { weekStartsOn: 1 }),
              now
            ) /
              10080) *
            100
          ).toFixed(2)
        ) * -1;

      res.words = `${differenceInDays(
        now,
        startOfWeek(now, { weekStartsOn: 1 })
      )}d ${differenceInHours(now, startOfDay(now))}h/${res.total}d`;
    }
    return res;
  }

  if (type === PAGES.MONTHS) {
    res.total = getDaysInMonth(spaceDate);
    if (isBefore(spaceDate, startOfMonth(now))) {
      res.percentage = 100;
      res.elapsed = res.total;
      res.words = `${res.elapsed}/${res.total}d`;
    } else if (isAfter(spaceDate, endOfMonth(now))) {
      res.percentage = 0;
      res.elapsed = 0;
      res.words = `${res.elapsed}/${res.total}d`;
    } else {
      // only 2 digits after the decimal
      res.percentage =
        parseFloat(
          (
            (differenceInMinutes(startOfMonth(spaceDate), now) /
              (60 * 24 * getDate(lastDayOfMonth(now)))) *
            100
          ).toFixed(2)
        ) * -1;
      res.words = `${differenceInDays(
        now,
        startOfMonth(now)
      )}d ${differenceInHours(now, startOfDay(now))}h/${res.total}d`;
    }
    return res;
  }

  if (type === PAGES.YEARS) {
    res.total = getDaysInYear(spaceDate);
    if (isBefore(spaceDate, startOfYear(now))) {
      res.percentage = 100;
      res.elapsed = res.total;
      res.words = `${res.elapsed}/${res.total}d`;
    } else if (isAfter(spaceDate, endOfYear(now))) {
      res.percentage = 0;
      res.elapsed = 0;
      res.words = `${res.elapsed}/${res.total}d`;
    } else {
      // only 2 digits after the decimal
      res.percentage =
        parseFloat(
          (
            (differenceInMinutes(startOfYear(spaceDate), now) /
              (60 * 24 * getDaysInYear(now))) *
            100
          ).toFixed(2)
        ) * -1;

      res.words = `${differenceInDays(
        now,
        startOfYear(now)
      )}d ${differenceInHours(now, startOfDay(now))}h/${res.total}d`;
    }
    return res;
  }

  return null;
}

export { getPeriodPercentage };
